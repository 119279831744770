<!-- 列表表头说明文字 -->
<template>
  <div class="statistics">
    <p class="title">
      <i class="el-icon-refresh shuaxinImg" @click="refresh"></i>
      <!-- <span v-if="isQueryCount">共{{total}}条数据,</span> -->
      {{ $t("vue_label_pool_summary", [total, timeInterval]) }}
      <!-- <span >共 {{total}} 条数据,已在 {{timeInterval}} 
        {{$t('label.refresh')}}
        </span>  -->
      <span v-if="field !== ''">
          <!-- 按{{field.trim()}}排序 -->
          {{$t('vue_label_commonobjects_view_sortby',{field:field.trim()})}}
      </span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      default: 0,
    },
    showCheckedLength: {
      type: Boolean,
      default: true,
    },
    checkedLength: {
      type: Number,
      default: 0,
    },
    field: {
      type: String,
      default: "",
    },
    timeInterval: {
      type: String,
      default: "",
    },
    isQueryCount: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  created() {},
  methods: {
    // 刷新
    refresh() {
      this.$emit("refresh");
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics {
  .shuaxinImg {
    cursor: pointer;
    margin-right: 5px;
  }

  .title {
    font-size: 12px;
  }

  img {
    position: relative;
    top: -1px;
  }
}
</style>
