<template>
  <!-- 公海池页面 -->
  <div class="objectTableMain">
    <!-- 当前页面div -->
    <div
      style="
        border-radius: 3px;
        border: 1px solid #dedcda;
        height: calc(100% - 50px);
      "
    >
      <!-- 顶部菜单行块 -->
      <div class="pull-left topContent" v-if="!isEmpty">
        <!-- 头部的标题模块部分 -->
        <div class="pull-left left-content" style="padding-top: 10px">
          <!-- 切换视图 -->
          <div class="meter-box">
            <div class="switch-views">
              <choose-view
                class="pull-left choose-view"
                :object-name="tabName"
                :viewId="viewId"
                :view-list="viewList"
                :view-name="viewName"
                @changeView="changeView"
                :objId="objId"
                :prefix="prefix"
                :targetFlag="true"
                v-if="this.viewLength > 0"
                :tabStyle="tabStyleComputed"
              ></choose-view>
            </div>
            <!-- 查看对象菜单【客户、潜在客户】 -->
            <div class="check-box">
              <!-- 左上角单选框  -->
              <div class="left_checkbox">
                <el-radio-group v-model="checkdFilterStr">
                  <el-radio
                    v-for="item in filterListArr"
                    :label="item.codeValue"
                    :key="item.id"
                    @change="filterChange"
                    >{{ item.name }}</el-radio
                  >
                </el-radio-group>
              </div>
            </div>
          </div>
        </div>
        <!-- 右面按钮操作组 -->
        <div class="pull-right right-content">
          <button-wrapper
            class="pull-right"
            ref="wrapper"
            :addable="addable"
            :viewId="viewId"
            :tabName="tabName"
            :prefix="prefix"
            :show-buttons="showBtnsTmp"
            @add="add"
            @addTag="addTag"
            @refresh="refresh"
            @clientPoolRightBtnClick="clientPoolRightBtnClick"
            :tableCheckedObjectArr="tableCheckedObjectArr"
            :pageFlag="pageFlag"
            :clientPoolId="clientPoolId"
            :currentClientPoolSelectObj="currentClientPoolSelectObj"
          ></button-wrapper>
        </div>
      </div>
      <!-- 刷新时间和右侧搜索行 -->
      <div class="pull-left topContent" v-if="!isEmpty">
        <div class="pull-left left-content">
          <!-- 列表表头说明文字 -->
          <headerText
            :total="totalSize"
            :field="sortFieldName"
            :show-checked-length="type !== 'kanban'"
            :time-interval="timeInterval"
            :checked-length="
              ($refs.tablePanel && $refs.tablePanel.checkedList.length) ||
              lengths
            "
            @refresh="refresh"
          ></headerText>
        </div>
        <div class="pull-right right-content" style="padding-top: 0">
          <!-- 搜索框 -->
          <search-wrapper
            ref="searchWrapper"
            class="searchWrapper pull-right"
            :width="inputWidth"
            @changeKeyWord="changeKeyWord"
          ></search-wrapper>
        </div>
      </div>
      <!-- 空白页面时的左侧图标和标题 -->
      <div class="topmenu-left-empty" v-if="isEmpty">
        <svg class="viewImg" aria-hidden="true">
          <use
            :href="
              titleObjImg
                ? '#icon-' + titleObjImg
                : targetImg
                ? '#icon-' + targetImg
                : '#icon-hometab'
            "
          ></use>
        </svg>
        <div>{{ titObjName }}</div>
      </div>
      <!-- 公海池缺省空白页面 -->
      <div v-if="isEmpty" class="clientPool-empty-style">
        <div>
          <svg aria-hidden="true">
            <use xlink:href="#icon-gonghaichi-zhanweitu"></use>
          </svg>
          <div>{{ $t("vue_label_pool_nopool_notice") }}</div>
        </div>
      </div>
      <!-- 列表 -->
      <table-panel
        v-if="!isEmpty"
        class="text-center"
        ref="tablePanel"
        :border="true"
        :show-index="true"
        :checked="true"
        :customHeader="true"
        :table-height="tableHeight"
        :obj-id="objId"
        :view-id="viewId"
        :table-attr="tableAttr"
        :page-obj="pageObj"
        :table-button="tableBtnList"
        :noDataTip="noDataTip"
        :view-ismodify="viewIsmodify"
        :viewSelectedFieldList="viewSelectedFieldList"
        :viewUnselectedFieldList="viewUnselectedFieldList"
        :pict-loading="pictLoading"
        :objectApi="objectApi"
        @searchViewDetail="searchViewDetail"
        @detail="detail"
        @changeSortFiled="changeSortFiled"
        @locked="locked"
        @edit="edit"
        @del="del"
        @saveSelectedField="saveSelectedField"
        @onchange="onchange"
      >
      </table-panel>
      <!-- 删除数据提示 -->
      <el-dialog
        :visible.sync="dialogVisible"
        :title="$t('component_setup_tabs_label_delete')"
        top="15%"
        width="25%"
        :close-on-click-modal="false"
        :before-close="handleClose"
      >
        <!-- 确认删除该条数据吗？ -->
        <span style="font-size: 16px; font-weight: bold">{{
          $t("label.weixin.confirm.delete") + "?"
        }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false" size="mini">
            <!-- 取消 -->
            {{ $t("label.cancel") }}</el-button
          >
          <el-button @click="confirmDel" size="mini">
            <!-- 删除 -->
            {{ $t("label.delete") }}
          </el-button>
        </span>
      </el-dialog>
      <!-- 查找/查找多选 -->
      <el-dialog
        :title="$t('label.searchs')"
        custom-class="dialog-form-wrapper"
        width="60%"
        top="5%"
        style="height: 91%"
        :visible.sync="showSearchTable"
        :lock-scroll="true"
        :close-on-click-modal="false"
        :modal="true"
        append-to-body
      >
        <search-table
          ref="searchTable"
          :fieldId="fieldId"
          :checked="checked"
          :relevant-objid="relevantObjId"
          :relevant-prefix="relevantPrefix"
          @changeSelect="changeSelect"
        />
      </el-dialog>

      <!-- 标签列表 -->
      <LabelView
        class="LabelView"
        v-if="isLabelView"
        :objId="objId"
        :getViewListDataParams="getViewListDataParams"
        @tagValue="tagValues"
        @gb="getMsgFormSon"
      ></LabelView>

      <!-- 新增/修改记录/删除记录 -->
      <create-edit-obj
        ref="createEditObj"
        :prefix="prefix"
        :id="id"
        :objectApi="objectApi"
        :object-name="tabName"
        @save="save"
        :pageFlag="pageFlag"
        :currentClientPoolSelectObj="currentClientPoolSelectObj"
        :isClientPoolEditStatus="isClientPoolEditStatus"
        :realObjId="objId"
        :relativeRecordId="clientPoolId"
        :relationFieldId="relationFieldId"
      >
      </create-edit-obj>
      <!-- 添加标签 -->
      <div class="add_label">
        <add-label :str="str" :objId="objId" ref="addlabel"> </add-label>
      </div>
    </div>
    <!-- 分页 -->
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="skippageNum"
        :page-sizes="[10, 25, 50, 100, 200]"
        :page-size="pageSize"
        :layout="this.layout"
        :total="this.totalSize"
        :page-count="pageCount"
        :prev-text="prevText"
        :next-text="nextText"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
/**
 * 公海池
 */
import {
  ButtonWrapper,
  ChooseView,
  SearchWrapper,
  TablePanel,
  LabelView,
} from "@/components/index";
import createEditObj from "@/views/commonObjects/components/create-edit-obj.vue";
import createButton from "@/utils/Button";
import { Message } from "element-ui";
import * as CommonObjApi from "./api";
import addLabel from "@/components/LabelView/addTag.vue";
import headerText from "./components/headerText"; //表头文字

export default {
  components: {
    headerText,
    ButtonWrapper,
    ChooseView,
    SearchWrapper,
    TablePanel,
    LabelView,
    addLabel,
    createEditObj,
  },
  data() {
    return {
      isQueryCount: false,
      // 是否管理员是则显示所有数据（表格数据）[字符串 'true'/'false']
      isOwnerData: "",
      // 公海池ID
      clientPoolId: "",
      // 是否没有加载完成
      isNotLoadingCompleteTablePanel: false,
      // 子组件传过来的路由参数
      sonParameter: "",
      // 左上角的筛选条件列表,选中的过滤条件（永远穿中文给后台）
      checkdFilterStr: "未分配",
      flag: "",
      rowIndex: "",
      apiname: "",
      ind: 0,
      alldataists: [],
      recordId: "", //主从记录ID
      editableCellValue: { value: "" },
      ownerName: "",
      ownerId: "",
      clientOwnerName: "",
      dataLists: [],
      showSearchTable: false,
      fieldId: "",
      relevantObjId: "",
      relevantPrefix: "",
      checkList: [],
      zhucongfield: [],
      fieldlist: [], //表头信息
      isaddShow: true,
      stateone: "",
      validRule: true,
      state: "",
      selectNum: 0,
      checked: false,
      isSendMail: false,
      optionList: {},
      pictLoading: false,
      printFlag: false,
      lengths: 0,
      showKanban: false,
      showBaseForm: false,
      showScreen: false, // 是否显示筛选器
      isLabelView: false, //显示标签
      isChart: false, //显示图表
      viewIsmodify: false, // 当前视图是否可编辑
      url: "",
      tabName: "", //左上角的 名称（对象名称）
      objectApi: this.$route.params.objectApi,
      objectApis: [], //主从objectApis
      relatedApinames: [], //主从relatedApiname
      type: this.$route.params.type, // 视图类型
      objId: this.$route.params.objId, // 对象id
      prefix: this.$route.params.prefix, // 对象前缀
      tableSearchParams: {},
      inputWidth: "300px",
      tableHeight: "",
      // 表格表头数组
      tableAttr: [],
      // 页面表格数据【对象包数组】
      pageObj: {
        dataList: [],
      },
      tableBtnList: {
        // 操作
        title: this.$i18n.t("label.operate"),
        buttonList: [
          createButton({
            // 编辑
            label: this.$i18n.t("label.modify"),
            action: "edit",
          }),
          createButton({
            // 删除
            label: this.$i18n.t("label.delete"),
            action: "del",
          }),
        ],
      },
      dialogVisible: false,
      // 右边权限判断后的按钮（给组件显示）
      showBtns: [],
      // 右边权限判断后的按钮（给组件临时显示，当筛选条件为空时，将 showBtns赋值给此值显示初始按钮）
      showBtnsTmp: [],
      viewName: "",
      viewId: this.$route.query.viewId,
      viewList: [], // 视图列表
      viewLength: 0,
      skippageNum: 1, //页码
      pageSize: 50, // 每页展示数据条数
      totalSize: 0, // 总记录数
      layout: "total, sizes, prev, pager, next, jumper",
      prevText: "", //上一页按钮文字
      nextText: "", //下一页按钮的文字
      isTotal: false, //是否显示第几页和回到首页按钮
      noDataTip: false,
      sortField: "", // 排序字段
      sortFieldName: "",
      sortDir: "asc", // 排序方式
      viewSelectedFieldList: [],
      viewUnselectedFieldList: [],
      searchKeyWord: "", // 搜索关键字
      conditionValues: "",
      tagIds: [], // 搜索标签
      isAllTag: "", // 是否同时满足所有标签
      isallsearch: "false",
      myself: "false",
      scrollId: "false",
      getViewListDataParams: {},
      id: "", //记录id
      timer: "", //定义一个定时器的变量
      lastRefresh: new Date(), // 最后一次更新时间
      timeInterval: "",
      labelArr: [],
      str: "",
      viewInfo: {},
      strs: [],
      relatedItem: {}, // 事件类型的相关项
      currentUserInfo: {}, // 当前登录用户
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      addable: "",
      ismodifyFalg: "", //判断视图权限
      /**
       * 公海池部分参数
       */
      filterListArr: [], //左侧的按钮的数组
      tableCheckedObjectArr: [], // 表格所选中的所有的对象
      pageFlag: "clientPool", //页面标记公海池（告诉组件，我当前是公海池页面使用）
      isEmpty: false, //公海池是否为空（没有在后台创建公海池）
      currentClientPoolSelectObj: {}, //当前的选中的公海池对象【id和name】
      isClientPoolEditStatus: false,
    };
  },
  created() {
    if (this.objId === "lead") {
      // 潜在客户
      document.title = this.$setTitle(this.$i18n.t("vue_label_leadpool"));
    } else if (this.objId === "account") {
      // 客户
      document.title = this.$setTitle(this.$i18n.t("vue_label_accountpool"));
    }
    this.showKanban = this.type === "table" ? false : true;
    // 获取左侧下拉公海池列表
    this.queryMarketSeaList();
  },
  mounted() {
    this.tableHeight = "100%";
    // 计时器计算多久前更新
    this.timer = setInterval(() => {
      // 获取当前时间
      let currentTime = new Date();
      let interval = currentTime - this.lastRefresh;
      //计算出相差天数
      var days = Math.floor(interval / (24 * 3600 * 1000));
      //计算出小时数
      var leave1 = interval % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000));
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000));
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      hours = minutes > 30 ? hours + 1 : hours;
      if (days > 0) {
        this.timeInterval =
          hours > 0
            ? `${days} ${this.$i18n.t(
                "label.login.access.day"
              )}${hours} ${this.$i18n.t("label_tabpage_hoursagoz")}`
            : `${days} ${this.$i18n.t("label_tabpage_Sdfez")}`;
      } else if (hours > 0) {
        this.timeInterval = `${hours} ${this.$i18n.t(
          "label_tabpage_hoursagoz"
        )}`;
      } else if (minutes > 0) {
        this.timeInterval = `${minutes} ${this.$i18n.t(
          "label_tabpage_minutesagoz"
        )}`;
      } else {
        this.timeInterval =
          seconds > 43
            ? this.$i18n.t("vue_label_commonobjects_view_secondsbefore", {
                seconds: seconds,
              })
            : this.$i18n.t("label.notification.time1");
      }
    }, 1000);
  },
  methods: {
    // 获取视图列表（左侧下拉）
    async queryMarketSeaList() {
      this.viewName = "";
      let res = await CommonObjApi.queryMarketSeaList({
        id: this.objId,
      });
      if (res.data.length < 1) {
        // 公海池是否为空（没有在后台创建公海池）
        this.isEmpty = true;
        return;
      } else {
        this.isEmpty = false;
      }
      this.clientPoolId = res.data[0].id;
      this.currentClientPoolSelectObj = res.data[0]; //当前的选中的公海池对象【id和name】
      this.viewList = res.data;
      this.viewList.map((item) => {
        // 获取列表视图/看板视图带过来的视图信息
        if (item.id === this.viewId) {
          item.isCurrent = true;
          this.viewName = item.name;
          this.viewInfo = item;
          this.ismodifyFalg = item.ismodify;
          // this.business();
        } else {
          item.isCurrent = false;
        }
      });
      if (this.viewName === "") {
        this.viewList.map((item) => {
          // 获取默认视图信息
          if (item.isdefault === "1") {
            item.isCurrent = true;
            this.viewName = item.name;
            this.viewId = item.id;
            this.viewInfo = item;
            this.ismodifyFalg = item.ismodify;
          } else {
            item.isCurrent = false;
          }
        });
      }
      // 若接口返回视图列表无默认视图，将第一个视图作为显示视图
      if (this.viewName === "") {
        this.viewList[0].isCurrent = true;
        this.viewName = this.viewList[0].name;
        this.viewId = this.viewList[0].id;
        this.viewInfo = this.viewList[0];
        this.ismodifyFalg = this.viewList[0].ismodify;
      }
      this.viewLength = this.viewList.length;
      this.queryHeaderByMarketId();
    },
    // 设置视图展示字段（判断非管理员无法编辑）
    searchViewDetail() {
      if (
        (this.viewName === "最新记录" ||
          this.viewName === "我最近查看的" ||
          this.viewName === "我最近创建的" ||
          this.viewName === "我最近修改的") &&
        this.ismodifyFalg !== "true"
      ) {
        this.viewIsmodify = false;
      } else {
        this.viewIsmodify = true;
        this.getFieldSetup();
      }
    },
    // 保存视图选中字段
    saveSelectedField(ids) {
      CommonObjApi.saveFieldSetup({
        objId: this.objId,
        viewId: this.viewId,
        fieldIds: ids,
      }).then(() => {
        this.pageNum = 1;
        this.queryDataByMarketId();
        this.$message.success("保存成功");
      });
    },
    // 获取视图详细信息
    getFieldSetup() {
      this.viewSelectedFieldList = [];
      this.viewUnselectedFieldList = [];
      CommonObjApi.getFieldSetup({
        objId: this.objId,
        viewId: this.viewId,
      }).then((res) => {
        this.viewSelectedFieldList = res.data.viewSelectedFieldList;
        this.viewUnselectedFieldList = res.data.viewUnselectedFieldList;
      });
    },
    // 获取视图列表表头和按钮权限
    async queryHeaderByMarketId() {
      this.showScreen = false;
      this.isLabelView = false;
      this.isChart = false;
      this.pictLoading = true;
      this.isNotLoadingCompleteTablePanel = true;
      this.tableAttr = [];
      let res = await CommonObjApi.queryHeaderByMarketId({
        id: this.clientPoolId,
      });
      // 当前对象名称
      this.tabName = res.data.labelname;
      // 根据获取的权限，判断左侧按钮显示哪几个
      // this.addable = res.data.objServices.add;
      this.tableAttr = res.data.fieldList;
      // 全部按钮（请求获取到所有的按钮，根据不同的筛选条件赋值给 showBtnsTmp 展示不同的按钮）
      this.showBtns = res.data.button;
      // 筛选条件切换后，临时显示的按钮
      this.showBtnsTmp = res.data.button;
      res.data.fieldList.forEach((item, idx) => {
        // 名称、查找、主详、查找多选均可跳转
        if (
          item.schemefieldName === "name" ||
          item.schemefieldType === "Y" ||
          item.schemefieldType === "M" ||
          item.schemefieldType === "MR"
        ) {
          this.$set(this.tableAttr[idx], "click", "detail");
        }
        this.$set(this.tableAttr[idx], "sortByThis", false);
        this.$set(this.tableAttr[idx], "sortDir", "asc");
        this.$set(this.tableAttr[idx], "fixed", false);
        this.$set(this.tableAttr[idx], "locked", false);
      });
      this.pictLoading = false;
      this.$refs.tablePanel.changeTableAttr(this.tableAttr);
      if (res.data && res.data.role === "manager") {
        this.isOwnerData = "true";
        this.getFilterListArr();
        this.filterChange();
      } else {
        this.isOwnerData = "false";
        this.getFilterListArr();
        this.filterChange();
      }
    },
    // 刷新
    refresh() {
      this.sortField = "";
      this.sortFieldName = "";
      this.lastRefresh = new Date();
      // 重置列表信息
      this.pageObj.dataList = [];
      this.pageNum = 1;
      // 重置列表选中统计数据
      this.$refs.tablePanel.checkedList = [];
      // 清空选择的公海池数据
      this.tableCheckedObjectArr = [];
      this.queryDataByMarketId();
    },
    // 搜索条件改变
    changeKeyWord(val) {
      this.pageNum = 1;
      this.searchKeyWord = val;
      this.queryDataByMarketId();
    },
    // 获取视图数据  ------------------------------------------------------------
    async queryDataByMarketId() {
      /**
       * 参数说明
       * 参数名	必选	类型	说明
       * obj	是	string	对象前缀
       * viewId	是	string	视图Id
       * pageNum	是	string	页数
       * pageSize	是	string	每页数量
       * sortField	是	string	排序字段apiname
       * sortDir	是	string	排序类型(desc[降序]/asc[升序])
       * searchKeyWord	是	string	搜索关键字
       * conditionValues	是	string	高级搜索条件
       * ischangenum	是	string	是否修改每页查询数目（修改的数目为视图默认每页数量）
       * recordnums	是	string	每页查询默认数目
       * tagIds	否	string	标签id，多个标签逗号分隔，按选中标签查询
       * isAllTag	否	string	是否同时满足所有选中标签（true/false）
       * campaignid	否	string	市场活动成员是否只查询现有成员
       * isallsearch	否	string	是否启用启用全局搜索，conditionValues不用空也不启用全局搜索
       * myself	否	string	全局搜索是否只查询所有人是自己的记录
       * scrollId	否	string	全文检索查询返回值，下一页查询回传
       * type	否	string	contact/lead特殊查询使用以不使用全文检索，参数暂时不用
       */
      this.noDataTip = false;
      this.pictLoading = true;
      // 是否是我的数据（管理员一直传false,非管理员未领取false，其余都是true）
      let isOwner = "";
      // 如果是管理员
      if (this.isOwnerData === "true") {
        isOwner = "false";
      } else {
        if (this.checkdFilterStr === "未分配") {
          isOwner = "false";
        } else {
          isOwner = "true";
        }
      }
      let {
        data: { data, total },
      } = await CommonObjApi.queryDataByMarketId({
        marketSeaId: this.clientPoolId,
        objId: this.objId,
        // page: this.pageNum,
        page: this.skippageNum, //分页页码
        // pageSize: this.pageSize,
        pageSize: this.pageSize, //每页展示多少条
        // 排序字段apiname
        orderField: this.sortField,
        // 排序方式
        orderType: this.sortField === "" ? "" : this.sortDir,
        // 搜索关键词
        searchKeyWord: this.searchKeyWord,
        // 已分配、未分配等条件，多个条件用英文逗号分隔
        condition: this.checkdFilterStr,
        // 是否是我的数据（管理员一直传false,非管理员未领取false，其余都是true）
        isOwner, //true是我的数据 false是所有数据
      });
      this.totalSize = total;
      // 当还有下一页的时候，data会多返回一条数据，删除data多返回的最后一条数据
      if (data.length > this.pageSize) {
        data = data.slice(0, this.pageSize);
      }
      /**
       * 把上拉加载改为分页加载
       */
      if (total === 0 && this.pageNum === 1) {
        this.pageObj.dataList = [];
      } else if (this.pageObj.dataList.length === 0 || this.pageNum === 1) {
        // 页面加载进入到这里
        this.pageObj.dataList = data;
        this.pageNum++;
      } else {
        let checkedList = this.$refs.tablePanel.getCheckedList();
        this.pageObj.dataList = data;
        this.pageObj.dataList.forEach((element) => {
          checkedList.forEach((item) => {
            if (item.id === element.id) {
              element.checked = true;
            }
          });
        });

        this.pageNum++;
        this.$refs.tablePanel.dataList = this.pageObj.dataList;
      }
      setTimeout(() => {
        this.pictLoading = false;
      }, 500);
      this.lastRefresh = new Date();
      this.pageObj.totalSize = total;
    },
    // 切换视图
    changeView(view) {
      // 切换视图时，让第一个选中
      this.checkdFilterStr = "未分配";
      this.clientPoolId = view.id;
      this.currentClientPoolSelectObj = view; //当前选中的公海池对象
      this.viewList.map((item) => {
        // 获取默认视图信息
        if (item.id === view.id) {
          this.viewName = item.name;
          this.viewId = item.id;
          item.isCurrent = true;
          this.ismodifyFalg = item.ismodify;
        } else {
          item.isCurrent = false;
        }
      });
      // 刷新视图列表
      this.refresh();
      this.queryHeaderByMarketId();
    },
    // 新建
    add() {
      // 新建时要清空id，不然就会请求编辑时的数据咯
      this.id = "";
      // 公海池的编辑状态【在create-eit-obj组件】
      this.isClientPoolEditStatus = false;
      this.$nextTick(() => {
        this.$refs.createEditObj.add();
      });
    },
    // 获取当前登录用户
    getCurrentUser() {
      // 获取登录用户
      CommonObjApi.getUserInfo().then((res) => {
        this.currentUserInfo = {
          id: res.data.userId,
          name: res.data.userName,
        };
      });
    },
    // 获取当前系统时间的下一个整点以及整点之后一个小时的时间
    getHour() {
      let date = new Date();
      date.setHours(date.getHours() + 1);
      let y = date.getFullYear();
      let m =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let startTime = y + "-" + m + "-" + d + " " + h + ":00:00";
      function getNextHour(intPoint) {
        let dd = new Date(intPoint);
        let Y = dd.getFullYear();
        let M =
          dd.getMonth() + 1 < 10
            ? "0" + (dd.getMonth() + 1)
            : dd.getMonth() + 1;
        let D = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
        let H =
          dd.getHours() + 1 < 10
            ? "0" + (dd.getHours() + 1)
            : dd.getHours() + 1;
        return Y + "-" + M + "-" + D + " " + H + ":00:00";
      }
      return { start: startTime, end: getNextHour(startTime) };
    },
    // 编辑
    edit(row) {
      // 公海池的编辑状态【在create-eit-obj组件】
      this.isClientPoolEditStatus = true;
      this.id = row.data.id;
      this.$nextTick(() => {
        this.$refs.createEditObj.edit();
      });
    },
    // 改变查找/查找带值选项及值
    changeSelect(row) {
      let options = [];
      let value = {
        value: row.data.id,
      };
      if (row instanceof Array) {
        row.forEach((ele) => {
          options.push({
            label: ele.name,
            value: ele.id,
          });
        });
      } else {
        options.push({
          label: row.data.name,
          value: row.data.id,
        });
      }
      this.isZhucongfield &&
        this.$set(
          this.dataLists[this.ind][this.rowIndex - 1],
          this.apiname,
          value
        );
      this.isZhucongfield &&
        this.$set(
          this.dataLists[this.ind][this.rowIndex - 1],
          `${this.apiname}Options`,
          options
        );
      this.showSearchTable = false;
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;

      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    // 查找/查找多选
    remoteSearch(apiname, item, num, ind) {
      this.apiname = apiname;
      this.fieldId = item.fieldId;
      this.relevantObjId = item.lookupobj;
      this.rowIndex = num + 1;
      this.idx = num;
      this.ind = ind;
      this.isZhucongfield = true;
      this.showSearchTable = true;
      this.checked = item.type === "remote-multi-select" ? true : false;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    // 新增/修改保存
    save() {
      this.pageNum = 1;
      this.queryDataByMarketId();
    },
    // 弹窗关闭
    cancel() {
      this.dialogAttr.isShow = false;
      this.zhucongfield = [];
      this.alldataists = [];
      this.dataLists = [];
      this.row = "";
    },
    // 删除
    del(row) {
      this.id = row.data.id;
      this.dialogVisible = true;
    },
    // 确认删除数据
    confirmDel() {
      CommonObjApi.del({
        id: this.id,
        objectApi: this.objectApi,
      }).then(() => {
        this.pageNum = 1;
        this.dialogVisible = false;
        this.$message.success(this.$i18n.t("label.ems.delete.success"));
        this.queryDataByMarketId();
      });
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false;
    },
    // 详情
    detail(row) {
      // 查找字段跳到对应详情页
      // 事件及任务类型名称和相关项跳转
      if (
        row.field.schemefieldType === "Y" ||
        row.field.lookupObj === "user" ||
        row.field.schemefieldName === "relateid"
      ) {
        CommonObjApi.getPermissionById({
          id: row.data[row.field.schemefieldName],
        }).then((res) => {
          if (!res.data.isQuery) {
            this.$message.warning(
              // "抱歉，您无权查看该条数据，请联系系统管理员。"
              this.$i18n.t("2018UI_Homepage_DataNoAccess_Info")
            );
            return false;
          }
        });
      }
    },
    // 锁定
    locked(field) {
      let fiexdList = this.tableAttr.filter((item) => {
        return item.fixed === true;
      });

      if (fiexdList.length > 2) {
        Message.warning(
          this.$i18n.t("vue_label_commonobjects_view_fixed_at_most")
        );
        this.tableAttr.forEach((item) => {
          if (item.nameLabel === field.nameLabel) {
            item.fixed = false;
            item.locked = false;
          }
        });
      } else {
        this.tableAttr.forEach((item) => {
          if (item.nameLabel === field.nameLabel) {
            item.fixed = !item.fixed;
          }
        });
      }

      this.$forceUpdate();
      this.$refs.tablePanel.changeTableAttr(this.tableAttr);
    },
    // 修改排序字段
    changeSortFiled(field) {
      this.pageNum = 1;
      this.skippageNum = 1;
      this.pageObj.dataList = [];

      // 若排序字段不变，改变排序方式
      if (this.sortField === field.schemefieldName) {
        field.sortDesc && (this.sortDir = "desc");
        field.sortAsc && (this.sortDir = "asc");
        // this.sortDir = this.sortDir === "asc" ? "desc" : "asc";
      } else {
        // 否则重置排序字段和排序方式
        this.clearSort();
        this.sortDir = "asc";
        this.sortField = field.schemefieldName;
        this.sortFieldName = field.nameLabel;
      }
      // 刷新视图
      this.queryDataByMarketId();
      this.$forceUpdate();
      this.$refs.tablePanel.changeTableAttr(this.tableAttr);
    },
    // 清除排序
    clearSort() {
      this.tableAttr.forEach((item) => {
        item.sortByThis = false;
      });
    },
    getMsgFormSon(data) {
      this.isLabelView = data;
    },
    //筛选调用数据刷新
    recall() {
      this.showScreen = false;
      this.queryDataByMarketId();
    },
    //标签页向父组件传值
    tagValues(value) {
      this.pageObj.dataList = value;
    },
    addTag() {
      if (this.strs.length > 200 || this.lengths > 200) {
        this.$message.info(
          this.$i18n.t("vue_label_commonobjects_view_up_to_data")
        );
      } else {
        if (this.str === "") {
          this.$message.info(this.$i18n.t("lable.opppdt.selPdt.leastone"));
        } else {
          this.$refs.addlabel.isShows();
        }
      }
    },
    // 获取新添加的标签
    determineFun() {
      this.$refs.addlabel.isShows();
      this.getTagsByRecordId();
    },
    closeAddBtn() {
      this.$refs.addlabel.isShows();
    },
    getTagsByRecordId() {
      CommonObjApi.GetTagsByRecordId({
        objId: this.objId,
        recordId: this.id,
      }).then((res) => {
        this.labelArr = res.data;
      });
    },
    // 表格的勾选事件
    onchange(val) {
      // 表格所选中的所有的对象
      this.tableCheckedObjectArr = val;
    },
    //主从录入添加
    addul(ind) {
      this.dataLists[ind].push({});
      if (this.zhucongfield[ind] != "") {
        for (var i = 0; i < this.zhucongfield[ind].length; i++) {
          // 重置列表数据
          for (
            let num = this.dataLists[ind].length;
            num <= this.dataLists[ind].length;
            num++
          ) {
            this.$set(
              this.dataLists[i][num],
              this.zhucongfield[ind][i].apiname,
              { value: "" }
            );
          }
        }
      }
      if (this.dataLists[ind].length >= 30) {
        this.isShowThree = false;
      }
    },
    //主从录入删除
    deleteUl(num, ind) {
      // 主从录入可将数据全部删除
      if (this.zhucongfield[ind] != "" && this.dataLists[ind].length > 0) {
        this.dataLists[ind].splice(num, 1);
      }
      if (this.dataLists[ind].length < 30) {
        this.isShowThree = true;
      }
    },
    // 编辑值改变
    editValueChange(val, lineIndex, field, ind) {
      this.dataLists[ind][lineIndex][field] = { value: val };
    },
    //获取保存信息
    savemessages() {
      for (let i = 0; i < this.dataLists.length; i++) {
        let datalists = [];
        let drr = {};
        for (let line = 0; line < this.dataLists[i].length; line++) {
          let obj = {};
          this.$refs[`zhucongfield${this.objectApis[i]}`].forEach(
            (editableCell) => {
              if (editableCell.lineIndex === line) {
                obj[editableCell.field] =
                  Object.prototype.toString.call(editableCell.editValue) ===
                  Number
                    ? editableCell.editValue + ""
                    : editableCell.editValue;
              }
            }
          );
          datalists.push(obj);
        }
        this.row += datalists.length;
        drr.datalist = JSON.parse(JSON.stringify(datalists));
        drr.objectApi = this.objectApis[i];
        drr.relatedApiname = this.relatedApinames[i];
        this.alldataists.push(drr);
      }
    },
    //主从录入保存
    savedata() {
      this.savemessages();
      let param = {
        recordId: this.recordId,
        data: JSON.stringify(this.alldataists),
      };
      CommonObjApi.saveMaster(param).then((res) => {
        let errorMsg = "";
        res.data.forEach((item) => {
          if (item.isSaveSuccess !== "true") {
            errorMsg += this.$i18n.t(
              "vue_label_commonobjects_view_notice_deletefailreason",
              [item.id, item.errormessage]
            );
          }
        });
        if (errorMsg === "") {
          this.cancel();
        } else {
          this.$message.error(errorMsg);
        }
      });
    },
    async xiaoshou() {
      this.$router.push(`/commonObjects/hopper`);
    },
    recalles() {
      this.pageNum = 1;
      this.recall();
    },
    shows(val) {
      if (val === "kanban") {
        this.$refs.searchWrapper.isShows();
        this.$refs.wrapper.shows(false);
      } else {
        this.$refs.wrapper.shows(true);
      }
    },
    /**
     * 筛选条件改变
     * 判断右侧显示那些按钮
     */
    async filterChange() {
      // 清空之前表格选中的数据，防止右侧按钮没有监听到改变
      if (this.$refs.tablePanel) {
        this.$refs.tablePanel.checkedList = [];
      }
      this.pageNum = 1;
      this.queryDataByMarketId();
      /**
       * TODO 客户和潜在客户的筛选条件一样
       */
      let showBtnsTmp = JSON.parse(JSON.stringify(this.showBtns));
      if (this.isOwnerData === "true") {
        // 如果是管理员
        switch (this.checkdFilterStr) {
          case "未分配":
            // 显示所有
            this.showBtnsTmp = showBtnsTmp.filter((item) => {
              if (
                item.name !== "get" &&
                item.name !== "recover" &&
                item.name !== "back" &&
                item.name !== "recycle"
              ) {
                return item;
              }
            });
            break;
          case "已分配":
            // 显示回收
            this.showBtnsTmp = showBtnsTmp.filter(
              (item) => item.name === "recycle"
            );
            break;
          case "已转换":
            // 显示回收
            this.showBtnsTmp = showBtnsTmp.filter(
              (item) => item.name === "recycle"
            );
            break;
          case "已废弃":
            // 显示恢复
            this.showBtnsTmp = showBtnsTmp.filter(
              (item) => item.name === "recover"
            );
            break;
          case "自建":
            // 显示回收
            this.showBtnsTmp = showBtnsTmp.filter(
              (item) => item.name === "recycle"
            );
            break;
        }
      } else if (this.isOwnerData === "false") {
        // 如果是成员
        switch (this.checkdFilterStr) {
          case "未分配":
            //  显示领取
            this.showBtnsTmp = showBtnsTmp.filter(
              (item) => item.name === "get" || item.name === "Batch Added"
            );
            break;
          case "已分配":
            //  显示退回
            this.showBtnsTmp = showBtnsTmp.filter(
              (item) => item.name === "back"
            );
            break;
          case "已转换":
            //  显示退回
            this.showBtnsTmp = showBtnsTmp.filter(
              (item) => item.name === "back"
            );
            break;
          case "自建":
            //  显示退回
            this.showBtnsTmp = showBtnsTmp.filter(
              (item) => item.name === "back"
            );
            break;
        }
      }
    },
    // 右边按钮点击事件
    async clientPoolRightBtnClick(clickEvent, formData) {
      /**
       * 参数名	必选	类型	说明
          marketseaId	是	string	公海池id
          objectApi	是	String	对象api
          objid	是	string	对象obid
          id	是	String	客户或者潜在客户id
          type	是	string	领取（collection），分配（distribution）
          distributeid	是	string	分配给的id，若为领取则为null
       */
      // 当前勾选的客户id，多个用逗号分隔
      let id = this.tableCheckedObjectArr.map((item) => item.id).join(",");
      // 场景判断
      if (clickEvent.oprationDirctive === "Get") {
        // 领取
        let res = await CommonObjApi.marketseaCollectionAndDistribution({
          marketseaId: this.clientPoolId,
          objectApi: this.$route.params.objectApi,
          objid: this.objId,
          id,
          type: "collection",
          distributeid: "",
        });

        if (res.result) {
          this.pageNum = 1;
          if (res.data.boolean === true) {
            this.$message.success(this.$i18n.t("label.operatesuccess"));
          } else {
            this.$message.warning(
              this.$i18n.t("lable.the.united.arab.emirates")
            );
          }
          // 重新请求数据刷新页面
          this.queryDataByMarketId();
        } else {
          this.$message.warning(res.returnInfo);
        }
      } else if (clickEvent.oprationDirctive === "Assign") {
        // 分配
        let res = await CommonObjApi.marketseaCollectionAndDistribution({
          marketseaId: this.clientPoolId,
          objectApi: this.objectApi,
          objid: this.objId,
          id,
          type: "distribution",
          distributeid: formData.firstSelect,
        });
        if (res.result) {
          this.pageNum = 1;
          this.$message.success(this.$i18n.t("label.operatesuccess"));
          // 重新请求数据刷新页面
          this.queryDataByMarketId();
        } else {
          this.$message.warning(res.returnInfo);
        }
      } else if (clickEvent.oprationDirctive === "Back") {
        // 退回
        /**
         * 退回与回收
        参数名	必选	类型	说明
        marketseaid	是	string	公海池id
        objectApi	是	string	对象api
        thyyms	是	string	详细原因(不需要原因，传空)
        id	是	string	客户/潜在客户id，若多个则用逗号隔开
        type	是	string	类型：退回（return），回收（recovery）
        rejectreason	是	string	原因(不需要原因，传空)
         */

        let res = await CommonObjApi.marketseaReturnAndRecovery({
          marketseaid: this.clientPoolId,
          objectApi: this.objectApi,
          thyyms: formData.secondDesc,
          id,
          type: "return",
          rejectreason: formData.firstSelect,
        });
        if (res.result) {
          this.pageNum = 1;
          this.$message.success(this.$i18n.t("label.operatesuccess"));
          // 重新请求数据刷新页面
          this.queryDataByMarketId();
        } else {
          this.$message.warning(res.returnInfo);
        }
      } else if (clickEvent.oprationDirctive === "Recycle") {
        // 回收
        /**
         * 退回与回收
        参数名	必选	类型	说明
        marketseaid	是	string	公海池id
        objectApi	是	string	对象api
        thyyms	是	string	详细原因(不需要原因，传空)
        id	是	string	客户/潜在客户id，若多个则用逗号隔开
        type	是	string	类型：退回（return），回收（recovery）
        rejectreason	是	string	原因(不需要原因，传空)
         */
        let res = await CommonObjApi.marketseaReturnAndRecovery({
          marketseaid: this.clientPoolId,
          objectApi: this.objectApi,
          thyyms: "",
          id,
          type: "recovery",
          rejectreason: "",
        });
        if (res.result) {
          this.pageNum = 1;
          this.$message.success(this.$i18n.t("label.operatesuccess"));
          // 重新请求数据刷新页面
          this.queryDataByMarketId();
        } else {
          this.$message.warning(res.returnInfo);
        }
      } else if (clickEvent.oprationDirctive === "Discard") {
        // 废弃
        /**
        废弃与恢复
        参数名	必选	类型	说明
        objectApi	是	String	对象api
        id	是	String	客户或者潜在客户id，若为多个则用逗号隔开
        type	是	string	废弃（abandon），废弃（recovery）
        abandonreason	是	string	废弃原因 若为恢复则为空
        fqyyms	是	string	废弃详细原因 若为恢复则为空
       */
        let res = await CommonObjApi.marketseaAbandonmentAndRecovery({
          objectApi: this.objectApi,
          id,
          type: "abandon",
          abandonreason: formData.firstSelect,
          fqyyms: formData.secondDesc,
        });
        if (res.result) {
          this.pageNum = 1;
          this.$message.success(this.$i18n.t("label.operatesuccess"));
          // 重新请求数据刷新页面
          this.queryDataByMarketId();
        } else {
          this.$message.warning(res.returnInfo);
        }
      } else if (clickEvent.oprationDirctive === "Recover") {
        // 恢复
        /**
        废弃与恢复
        参数名	必选	类型	说明
        objectApi	是	String	对象api
        id	是	String	客户或者潜在客户id，若为多个则用逗号隔开
        type	是	string	废弃（abandon），废弃（recovery）
        abandonreason	是	string	废弃原因 若为恢复则为空
        fqyyms	是	string	废弃详细原因 若为恢复则为空
       */
        let res = await CommonObjApi.marketseaAbandonmentAndRecovery({
          objectApi: this.objectApi,
          id,
          type: "recovery",
          abandonreason: "",
          fqyyms: "",
        });
        if (res.result) {
          this.pageNum = 1;
          this.$message.success(this.$i18n.t("label.operatesuccess"));
          // 重新请求数据刷新页面
          this.queryDataByMarketId();
        } else {
          this.$message.warning(res.returnInfo);
        }
      } else if (clickEvent.oprationDirctive === "Transfer") {
        //  转移
        /**
          参数名	必选	类型	说明
          marketseaId	是	string	公海池id
          objectApi	是	String	对象api
          id	是	String	客户或者潜在客户id，多个用逗号隔开
         */
        let res = await CommonObjApi.marketseaTransfer({
          marketseaid: formData.firstSelect,
          objectApi: this.objectApi,
          id,
        });
        if (res.result) {
          this.pageNum = 1;
          this.$message.success(this.$i18n.t("label.operatesuccess"));
          // 重新请求数据刷新页面
          this.queryDataByMarketId();
        } else {
          this.$message.warning(res.returnInfo);
        }
      } else if (clickEvent.oprationDirctive === "batchDelete") {
        // 批量删除
        CommonObjApi.batchDelete({
          ids: id,
          prefix: this.prefix,
        }).then((res) => {
          // 适配之前业务逻辑
          if (!res.result) {
            this.$message.warning(res.returnInfo);
          }

          // 如果删除失败，则提示给用户错误信息
          if (
            res.data.deleteerrorlist === undefined ||
            res.data.deleteerrorlist === null
          ) {
            this.pageNum = 1;
            this.$message.success(this.$i18n.t("label.operatesuccess"));
            // 重新请求数据刷新页面
            this.queryDataByMarketId();
          } else {
            let error = "数据删除失败，您没有被授权此服务，请联系系统管理员!";
            this.$message.error(error);
          }
        });
      } else if (clickEvent.oprationDirctive === "returnPool") {
        let params = {
          marketseaid: formData.backtopoolId,
          objectApi: this.objectApi,
          thyyms: formData.firstSelect,
          id,
          type: "retuen",
          rejectreason: formData.secondDesc,
        };
        await CommonObjApi.marketseaReturnAndRecovery(params);
      }
      await this.refresh();
    },
    // 左侧的按钮列表
    /**
     * 页面左上角复选框数据已分配，未分配...
     * 左上角的筛选条件列表（根据不同的对象，显示不同的筛选项）
     */
    getFilterListArr() {
      // 如果是 【 潜在客户 】
      if (this.objId === "lead") {
        // 管理员可见的筛选项
        if (this.isOwnerData === "true") {
          this.filterListArr = [
            {
              id: 1,
              name: this.$i18n.t("vue_label_pool_unassigned"),
              codeValue: "未分配",
            },
            {
              id: 2,
              name: this.$i18n.t("vue_label_pool_assigned"),
              codeValue: "已分配",
            },
            {
              id: 3,
              name: this.$i18n.t("vue_label_pool_obsolete"),
              codeValue: "已废弃",
            },
            {
              id: 4,
              name: this.$i18n.t("vue_label_pool_converted"),
              codeValue: "已转换",
            },
            {
              id: 5,
              name: this.$i18n.t("vue_label_pool_self-built"),
              codeValue: "自建",
            },
          ];
        } else {
          // 成员可见的筛选项
          this.filterListArr = [
            {
              id: 1,
              name: this.$i18n.t("vue_label_pool_unassigned"),
              codeValue: "未分配",
            },
            {
              id: 2,
              name: this.$i18n.t("vue_label_pool_adoptedbyme"),
              codeValue: "已分配",
            },
            {
              id: 3,
              name: this.$i18n.t("vue_label_pool_convertedbyme"),
              codeValue: "已转换",
            },
            {
              id: 4,
              name: this.$i18n.t("vue_label_pool_createdbyme"),
              codeValue: "自建",
            },
          ];
        }
        // 如果是【 客户 】
      } else if (this.objId === "account") {
        // 管理员可见的筛选项
        if (this.isOwnerData === "true") {
          this.filterListArr = [
            {
              id: 1,
              name: this.$i18n.t("vue_label_pool_unassigned"),
              codeValue: "未分配",
            },
            {
              id: 2,
              name: this.$i18n.t("vue_label_pool_assigned"),
              codeValue: "已分配",
            },

            {
              id: 3,
              name: this.$i18n.t("vue_label_pool_obsolete"),
              codeValue: "已废弃",
            },
            {
              id: 4,
              name: this.$i18n.t("vue_label_pool_deal_done"),
              codeValue: "已成交",
            },
            {
              id: 5,
              name: this.$i18n.t("vue_label_pool_self-built"), //我自建的
              codeValue: "自建",
            },
          ];
        } else {
          // 成员可见的筛选项
          this.filterListArr = [
            {
              id: 1,
              name: this.$i18n.t("vue_label_pool_unassigned"),
              codeValue: "未分配",
            },
            {
              id: 2,
              name: this.$i18n.t("vue_label_pool_adoptedbyme"),
              codeValue: "已分配",
            }, //我领取的
            {
              id: 3,
              name: this.$i18n.t("vue_label_pool_mydeal"),
              codeValue: "已成交",
            }, //我成交的
            {
              id: 4,
              name: this.$i18n.t("vue_label_pool_self-built"),
              codeValue: "自建",
            }, //我自建的
          ];
        }
      }
    },
    // 显示页数发生变化时
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryDataByMarketId();
    },
    // 当前页码发生变化时
    handleCurrentChange(val) {
      this.skippageNum = val;
      this.queryDataByMarketId();
    },
  },
  watch: {
    type(val) {
      if (val === "table") {
        this.lengths = 0;
        this.isChat(false);
        this.$refs.searchWrapper.isShowes();
      } else if (val === "kanban") {
        this.$refs.searchWrapper.isShows();
      } else if (val === "Opportunity") {
        this.showButtons = [
          "liebiaoshitu",
          "kanbanshitu",
          "fenpingshitu",
          "shezhi",
          "baobiao",
          "daochu",
          "dayin",
          "biaoqian",
          "tubiao",
          "shaixuan",
          "xiaoshou",
        ];
      }
    },
    objId() {
      if (this.objId === "lead") {
        // 潜在客户
        document.title = this.$setTitle(this.$i18n.t("vue_label_leadpool"));
      } else if (this.objId === "account") {
        // 客户
        document.title = this.$setTitle(this.$i18n.t("vue_label_accountpool"));
      }
      this.showKanban = this.type === "table" ? false : true;
      // 获取左侧下拉公海池列表
      this.queryMarketSeaList();
    },
    "$route.params.objectApi"(newVal) {
      this.objectApi = newVal;
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.objId = to.params.objId;
    next();
  },
  computed: {
    // 分页计算
    pageCount() {
      let { totalSize, pageSize } = this;
      return Math.ceil(totalSize / pageSize);
    },
    // 顶部的对象title名称
    titObjName() {
      let name = "";
      if (this.objId === "lead") {
        name = this.$i18n.t("vue_label_leadpool");
      } else if (this.objId === "account") {
        name = this.$i18n.t("vue_label_accountpool");
      }
      return name;
    },
    // 顶部的对象的title图片
    titleObjImg() {
      let img = "";
      if (this.objId === "lead") {
        //  潜在客户公海池
        img = "qianzaikehugonghaichi";
      } else if (this.objId === "account") {
        // 客户公海池
        img = "kehugonghaichi";
      }
      return img;
    },
    // 判断当前公海池要使用的icon
    tabStyleComputed() {
      let icon = "";
      if (this.objId === "lead") {
        //  潜在客户公海池
        icon = "qianzaikehugonghaichi";
      } else if (this.objId === "account") {
        // 客户公海池
        icon = "kehugonghaichi";
      }
      return icon;
    },
    // 根据当前公海池判断使用相关记录id
    relationFieldId() {
      // 潜在客户所属公海池:ffeleadmarketsea01,客户所属公海池字段:ffeaccountmarketsea1
      let id = "";
      if (this.objId === "lead") {
        //  潜在客户公海池
        id = "ffeleadmarketsea01";
      } else if (this.objId === "account") {
        // 客户公海池
        id = "ffeaccountmarketsea1";
      }
      return id;
    },
  },
  beforeDestroy() {
    localStorage.setItem("relevantObjectLevel", 0);
    // 在Vue实例销毁前，清除我们的定时器
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table--border {
  border-left: none;
  border-right: none;
  border-bottom: none;
}

::v-deep .el-table::before {
  height: 0;
}

::v-deep .el-table__fixed::before {
  height: 0;
}

.groupTitle {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding-left: 20px;
  background: #f0f0f0;
}

.objectTableMain {
  // border: 1px solid #fff;
  border-left: none;
  border-right: none;
  border-radius: 3px;
  position: relative;
  height: 100%;
  padding: 10px 10px 0 10px;
}

/* 筛选器位置 */
.screen {
  height: calc(100% - 116px);
  position: absolute;
  right: 0;
  top: 105px;
  z-index: 9;
}

/* 标签列表位置 */
.LabelView {
  height: 88%;
  position: absolute;
  right: 0;
  top: 100px;
  z-index: 9;
}

.topContent {
  width: 100%;
  /* background: #fff; */
}

.left-content {
  // width: 50%;
  padding: 10px 10px 0;
  .meter-box {
    position: relative;
    display: flex;
    ::v-deep .el-dropdown {
      display: inline-block;
    }
    .switch-views {
      .chooseView.pull-left.choose-view {
        width: 100%;
      }
    }
  }
  .check-box {
    // background-color: #666;
    width: 100%;
    height: 20px;
    position: absolute;
    top: 0px;
    left: 200px;
    .view-customers {
      color: #006dcc;
    }
    .left_checkbox {
      // display: inline-block;
      white-space: nowrap;
      // width: 100%;
      // background-color: red;
      // margin-left: 0px;
      margin-top: -2px;
    }
  }
}

.right-content {
  // width: 50%;
  padding: 13px 10px 0 0;

  .searchWrapper {
    margin-right: -3px;
    height: 41px;
  }
}

.add_label {
  position: absolute;
  top: 57px;
  left: 35%;
}
.selectnum {
  height: 12px;
  font-size: 12px;
  margin-top: -20px;

  font-weight: 400;
  color: #666666;
  padding-bottom: 22px;
}
.checkboxs {
  margin-left: 6px;
  font-size: 12px;
  line-height: 28px;
}
.spancolor {
  color: #fa6400;
}
.spans {
  height: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  padding-top: 12px;
}
.mtransferli {
  list-style: inside;
  height: 34px;
  font-size: 12px;

  font-weight: 400;
  color: #0c0b0b;
  line-height: 18px;
}
/*批量新增css*/
.tank {
  height: 480px;
  background: rgba(255, 255, 255, 1);
  z-index: 6;
  margin: 100px auto 0;
}
.title {
  height: 53px;
  line-height: 53px;
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid rgba(240, 240, 240, 1);
  color: rgba(109, 114, 120, 1);
  font-size: 18px;
}
.btnp {
  font-weight: bold;
  font-size: 17px;
}
.btnx {
  font-size: 16px;
}

.listulFir {
  height: 40px;
  min-width: 100%;
  border: 1px solid rgba(190, 225, 255, 1);
  border-left: none;
  display: inline-flex;
  background: rgba(222, 240, 253, 1);
}
.listulSec:nth-of-type(2n) {
  height: 40px;
  display: inline-flex;
  background: rgba(240, 248, 255, 1);
  min-width: 100%;
}
.listulSec:nth-of-type(2n + 1) {
  height: 40px;
  display: inline-flex;
  background: white;
  min-width: 100%;
}
.firstLi {
  border-right: 1px solid rgba(190, 225, 255, 1);
  min-width: 80px;
  text-align: center;
}
.secondLi {
  border-right: 1px solid rgba(190, 225, 255, 1);
  text-align: center;
  width: 180px;
  line-height: 40px;
}
::v-deep .el-input {
  height: 30px;
  width: 100%;
  text-align: center;
}
::v-deep .el-input__inner {
  height: 30px;
  width: 100%;
}
::v-deep .el-button--mini {
  padding: 7px 7px;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: rgba(51, 51, 51, 1);
}
::v-deep .el-input__icon {
  height: auto;
}
.al {
  margin: 0 24px;
  width: auto;
  overflow: auto;
  max-height: 250px;
  border: 1px solid rgba(190, 225, 255, 1);
}
::v-deep .el-input__icon {
  margin-top: -4px;
}
.tianjia {
  margin-top: 20px;
  cursor: pointer;
  width: 32px;
  margin-bottom: 10px;
  margin-left: 16px;
}
ul {
  margin-bottom: 0;
}

.clientPool-empty-style {
  background: #fff;
  width: 100vw;
  height: calc(100vh - 55px - 41px - 43px - 40px);
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  user-select: none;
  svg {
    width: 307px;
    height: 178px;
    margin: auto;
  }
}

.viewImg {
  margin-right: 10px;
  margin-top: 2px;
  width: 37px;
  height: 37px;
}
.topmenu-left-empty {
  padding: 10px;
  display: flex;
}
// 单选按钮样式
::v-deep .is-checked .el-radio__label {
  color: #005fb2;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  background: #005fb2;
  border-color: #005fb2;
}
.block {
  background: #fff;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  z-index: 19;
}
.text-center {
  height: calc(100% - 93px);
}
</style>
