import axios from '@/config/httpConfig'

/**
 * 请求获取用户信息
 * @param {data} 请求参数 
 * @param {enforce} 是否强制请求最新用户信息 
 * @returns 用户信息
 */
export function getUserInfo(data, enforce = false) {
    if (enforce || localStorage.getItem("userInformation") === null) {
        return axios.get('/user/getUserInfo', data)
    } else {
        return new Promise(function (resolve) {
            resolve(JSON.parse(localStorage.getItem("userInformation"))); // 回调成功返回
        })
    }
}

// 获取视图列表 (左侧下拉菜单列表,) // 获取对象下所有可见的公海池
export function queryMarketSeaList(data) {
    return axios.post('/marketsea/queryMarketSeaList', data)
}

// 获取视图列表表头   获取公海池列表表头及按钮权限 包含按钮等,左侧名称
export function queryHeaderByMarketId(data) {
    return axios.post('/marketsea/queryHeaderByMarketId', data)
}

//根据记录id获取标签
export function GetTagsByRecordId(data) {
    return axios.post('/tag/getTagsByRecordId', data)
}

// 获取视图列表记录（表格数据）
export function queryDataByMarketId(data) {
    return axios.post('/marketsea/queryDataByMarketId', data)
}

// 领取与分配 
export function marketseaCollectionAndDistribution(data) {
    return axios.post('marketseaFunction/marketseaCollectionAndDistribution', data)
}

//退回与回收
export function marketseaReturnAndRecovery(data) {
    return axios.post('marketseaFunction/marketseaReturnAndRecovery', data)
}

// 获取公海池成员  分配时使用(下拉选项)
export function getMarketseaSpecMem(data) {
    return axios.post('marketseaFunction/getMarketseaSpecMem', data)
}

// 转移公海池
export function marketseaTransfer(data) {
    return axios.post('marketseaFunction/marketseaTransfer', data)
}
// 转移公海池时查询可转入的公海池id(下拉选项)
export function selMarketsea(data) {
    return axios.post('marketseaFunction/selMarketsea', data)
}

//废弃与恢复
export function marketseaAbandonmentAndRecovery(data) {
    return axios.post('marketseaFunction/marketseaAbandonmentAndRecovery', data)
}
// 视图列表退回（公海池）
export function getReturnSeasPool(data) {
    return axios.post('/marketseaFunction/getReturnSeasPool', data)
}

// 获取视图字段设置
export function getFieldSetup(data) {
    return axios.post('/view/getFieldSetup', data)
}

// 保存视图字段设置
export function saveFieldSetup(data) {
    return axios.post('/view/saveFieldSetup', data)
}

//获取更改所有人页面信息-单条数据
export function getChangeOwner(data) {
    return axios.post('/transfer/getChangeOwner', data)
}

// 删除记录
export function del(data) {
    return axios.post('/objectdetail/delete', data)
}

//获取对象记录权限
export function getPermissionById(data) {
    return axios.post('/objectdetail/getPermissionById', data)
}

// 主从录入保存
export function saveMaster(data) {
    return axios.post('/master/saveMaster', data)
}

// 获取批量删除的数据
export function batchDelete(data) {
    return axios.post('/batch/batchDelete', data)
}



